// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/images/ac.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar {\n    background-color: #f8f9fa;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.product-card {\n    transition: transform 0.3s, box-shadow 0.3s;\n}\n\n.product-card:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n}\n\nbutton:hover {\n    transition: background-color 0.3s, transform 0.3s;\n    background-color: #0056b3;\n    transform: scale(1.05);\n}\n\n.bg-pro-list {\n    height: 92px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n\n}\n\n.list-products {\n    padding: 0 36px;\n    color: black;\n}\n\n.product-item img {\n    max-width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/css/productListPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,2BAA2B;IAC3B,yCAAyC;AAC7C;;AAEA;IACI,iDAAiD;IACjD,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,yDAAmD;IACnD,4BAA4B;IAC5B,sBAAsB;;AAE1B;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".sidebar {\n    background-color: #f8f9fa;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.product-card {\n    transition: transform 0.3s, box-shadow 0.3s;\n}\n\n.product-card:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n}\n\nbutton:hover {\n    transition: background-color 0.3s, transform 0.3s;\n    background-color: #0056b3;\n    transform: scale(1.05);\n}\n\n.bg-pro-list {\n    height: 92px;\n    background-image: url('/src/assets/images/ac.jpeg');\n    background-repeat: no-repeat;\n    background-size: cover;\n\n}\n\n.list-products {\n    padding: 0 36px;\n    color: black;\n}\n\n.product-item img {\n    max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
